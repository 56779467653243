.itemContainer {
  flex: 1;
  flex-direction: row !important;
  font-size: 0.9rem;
  border: 1px solid #eee;
  margin-right: 1rem;
  padding: 1rem 1rem;
  /*width: 100%;
  min-width: 100%;*/
}

.itemContainer p {
  line-height: 1.6rem;
  bottom: 0;
  margin-bottom: 0;
}
