$blue: #204d74;
$border-color: $blue !default;

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

$input-border-color: $blue !default;

@import "bootstrap/scss/bootstrap";

:root {
  /*branco*/
  --color-branco-100: #fff;
  /*cinza*/
  --color-cinza-500: #ddd;
  --color-cinza-600: #e9ecef;
  /*verde*/
  --color-verde-500: #6bfc03;
  /*amarelo*/
  --color-amarelo-500: #fcf803;
  /*azul*/
  --color-azul-400: #0ea5e9;
  --color-azul-500: #036ffc;
  --color-azul-600: #204d74;
  /*marrom*/
  --color-marrom-500: #ba994c;
  /*vermelho*/
  --color-vermelho-500: #fc1c03;
}

html,
body,
#root {
  height: 100%;
}

table thead tr th {
  font-size: 14px !important;
  text-transform: uppercase !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
}

table tbody tr td {
  vertical-align: middle !important;
  font-size: 14px !important;
}

table.table-striped {
  border: solid 1px #343a40;
}

// corrige comportamento estranho do Sweet Alert
html body.swal2-height-auto {
  height: 100% !important;
}

.container-fluid {
  max-width: 1280px;
}

// Corrige comportamento estranho dos campos que são preenchidos automaticamente pelo webkit
input:-webkit-autofill::first-line {
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: initial !important;
}

//Style Vesion Aplication
.text-version {
  font-size: 0.8rem;
  color: #999;
  margin-bottom: 0;
  padding-top: 2rem;
}

.text-user-name-header {
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 576px) {
  .swal2-modal {
    width: 98% !important;
  }

  .swal2-title {
    font-size: 1.5em !important;
  }

  .swal2-header,
  .swal2-content,
  .swal2-actions {
    padding: 0 !important;
  }
}
