.itemContainer {
    font-size: 14px;
    min-width: fit-content;
}

.itemContainer:last-child {
    border-bottom: solid 1px #777777;
}

.itemContainer div {
    border-color: #777777 !important;
}

@media (max-width: 992px) {
    .itemContainer > div:first-child {
        min-width: 350px;
        max-width: 350px;
    }
}

@media (max-width: 576px) {
    .itemContainer > div:first-child {
        min-width: 250px;
        max-width: 250px;
    }
}